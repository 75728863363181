import React from "react";
import { List } from "@react-md/list";
import { Sheet } from "@react-md/sheet";

import { useToggle } from "@react-md/utils";

import SideMenuDetails from "./SideMenuDetails";
const Sidemenu = () => {
  const [visible, show, hide] = useToggle(false);

  return (
    <>
      <div className=" text-xl  " onClick={show} id="show-sheet-position">
        &#9776;
      </div>
      <Sheet
        id="example-sheet-1"
        aria-label="Example Sheet"
        visible={visible}
        onRequestClose={hide}
        position="left"
      >
        <List>
          {/* mobile view side menu  */}

          <SideMenuDetails />
        </List>
      </Sheet>
    </>
  );
};

export default Sidemenu;
