import React, { Component } from "react";
import { Dialog } from "@react-md/dialog";
import { Link } from "react-router-dom";
import { useToggle } from "@react-md/utils";
import LoginDetails from "./LoginDetails";

const MobileLogin = () => {
  const [visible, enable, disable] = useToggle(false);

  return (
    <>
      <span onClick={enable} class="focus:outline-none pb-2  cursor-pointer">
        MY ACCOUNT
      </span>

      <Dialog
        id="simple-dialog"
        visible={visible}
        aria-labelledby="dialog-title"
        className="dialogboxmobileview"
        type="full-page"
        onRequestClose={disable}
      >
        <span className="close3" id="dialog-close" onClick={disable}>
          &times;
        </span>
        <LoginDetails />
      </Dialog>
    </>
  );
};

export default MobileLogin;
