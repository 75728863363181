import { api } from "../api"
import { baseurl } from "../components/Custom"

export const fetchCategories = () => {
    return async (dispatch) => {
        const url = `/menu `
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_CATEGORIES",
            payload: response,
        })
    }
}
export const fetchGallery = () => {
    return async (dispatch) => {
        const url = `/gallery `
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_GALLERY",
            payload: response,
        })
    }
}

export const fetchRegister = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/send-otp`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("email", formValues.email)
        formdata.append("full_name", formValues.full_name)
        formdata.append("password", formValues.password)
        formdata.append(
            "password_confirmation",
            formValues.password_confirmation
        )

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "FETCH_REGISTER",
            payload: response,
        })
        dispatch(stopSubmitting())
        setCookie("userToken", response.data.access_token, 2)
    }
}

export const userLogin = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/login`
        var formdata = new FormData()

        formdata.append("mobile", formValues.mobile)
        formdata.append("password", formValues.password)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "USER_LOGIN",
            payload: response,
        })
        dispatch(stopSubmitting())
        setCookie("userToken", response.data.access_token, 2)
        //window.location.reload();
    }
}
export const saveDate = (date) => {
    return {
        type: "SAVE_DATE",
        payload: date,
    }
}
const formatDate = (date) => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]
    let day = date.getDate()
    let monthName = months[date.getMonth()]

    return { day, month, year, monthName }
}

export const newEnquiry = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/new-enquiry`
        var formdata = new FormData()

        formdata.append("name", formValues.name)
        formdata.append("no_of_people", formValues.no_of_people)
        formdata.append("message", formValues.message)

        formValues.email &&
            formValues.email.length > 0 &&
            formdata.append("email", formValues.email)
        formdata.append("mobile", formValues.mobile)
        const cake_date = formatDate(formValues.date)
        let selected_cake_date = `${cake_date.day}-${cake_date.month}-${cake_date.year}`

        formdata.append("date", selected_cake_date)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "NEW_ENQUIRY",
            payload: response,
        })
        dispatch(stopSubmitting())
        // setCookie("userToken", response.data.access_token, 2);
    }
}
export const updateProfile = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/update-profile`
        const token = getCookie("userToken")
        var formdata = new FormData()

        formdata.append("full_name", formValues.full_name)

        formValues.email &&
            formValues.email.length > 0 &&
            formdata.append("email", formValues.email)

        const cake_date = formatDate(formValues.dob)
        let selected_cake_date = `${cake_date.day}-${cake_date.month}-${cake_date.year}`

        formdata.append("dob", selected_cake_date)

        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "UPDATE_PROFILE",
            payload: response,
        })
        dispatch(stopSubmitting())
        // setCookie("userToken", response.data.access_token, 2);
    }
}
export const validateOtp = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/validate-otp`
        var formdata = new FormData()
        formdata.append("mobile", formValues.mobile)
        formdata.append("otp", formValues.otp)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "VALIDATE_OTP",
            payload: response,
        })
        dispatch(stopSubmitting())
        setCookie("userToken", response.data.access_token, 2)
    }
}
export const validateOtp2 = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/validate-otp`
        var formdata = new FormData()
        formdata.append("mobile", formValues.mobile)
        formdata.append("otp", formValues.otp)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "VALIDATE_OTP2",
            payload: response,
        })
        dispatch(stopSubmitting())
        setCookie("userToken", response.data.access_token, 2)
    }
}
export const fetchPickedupLocation = () => {
    return async (dispatch) => {
        const url = `/config`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "FETCH_PICKEDUP_LOCATION",
            payload: response,
        })
    }
}
export const checkUser = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/me`
        const response = await api
            .post(url, { token })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "CHECK_USER",
            payload: response,
        })
    }
}

export const placeOrder = (
    products,
    contact_id,
    grandTotal,
    service_custom_field_1,
    custom_field_1,
    payment_mode,
    delivery_time
    // shipping_address
    // formValues,
    // actions
) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("userToken")
        const url = `/pos`
        var formdata = new FormData()

        const formatDeliveryTime = format_delivery_time(delivery_time)

        // formdata.append("service_custom_field_1", service_custom_field_1);
        service_custom_field_1 &&
            service_custom_field_1.length > 0 &&
            formdata.append("service_custom_field_1", service_custom_field_1)
        // formdata.append("service_custom_field_1", service_custom_field_1);
        formdata.append("contact_id", contact_id)
        formdata.append("res_waiter_id", 2)
        formdata.append("location_id", 2)
        formdata.append("custom_field_1", custom_field_1)
        formdata.append("discount_type", "percentage")
        formdata.append("discount_amount", 0)
        formdata.append("rp_redeemed", 0)
        formdata.append("rp_redeemed_amount", 0)
        formdata.append("tax_calculation_amount", 0)
        formdata.append("shipping_charges", 0)
        formdata.append("advance_balance", 0)
        formdata.append("change_return", 0.0)
        formdata.append("is_suspend", 0)
        formdata.append("recur_interval_type", "days")
        formdata.append("custom_field_4", formatDeliveryTime)

        formdata.append("size", "all")
        formdata.append("is_credit_sale", 0)
        formdata.append("final_total", grandTotal)
        formdata.append("discount_type_modal", "percentage")
        formdata.append("discount_amount_modal", 0.0)
        formdata.append("shipping_charges_modal", 0.0)
        formdata.append("status", "final")
        formdata.append("tax_rate_id", "")
        formdata.append("shipping_address", "")

        // formdata.append("resources",JSON.stringify(resources));

        formdata.append(
            "payment[0][amount]",
            payment_mode == "cash" ? grandTotal : 0
        )
        formdata.append("payment[0][method]", payment_mode)

        formdata.append("payment_mode", payment_mode)

        let count = 1
        products.forEach((item) => {
            formdata.append(`products[${count}][product_type]`, "single")
            formdata.append(`products[${count}][unit_price]`, item.max_price)
            formdata.append(`products[${count}][line_discount_type]`, "fixed")
            formdata.append(`products[${count}][line_discount_amount]`, 0.0)
            formdata.append(`products[${count}][item_tax]`, 0.0)
            formdata.append(`products[${count}][product_id]`, item.id)
            formdata.append(`products[${count}][variation_id]`, item.id)
            formdata.append(
                `products[${count}][enable_stock]`,
                item.enable_stock
            )
            formdata.append(`products[${count}][quantity]`, item.quantity)
            formdata.append(`products[${count}][product_unit_id]`, item.unit_id)
            formdata.append(`products[${count}][base_unit_multiplier]`, 1)
            formdata.append(
                `products[${count}][unit_price_inc_tax]`,
                item.max_price
            )
            formdata.append(`products[${count}][tax_id]`, "")
            count++
        })

        // formValues.service_custom_field_1 &&
        //   formValues.service_custom_field_1.length > 0 &&
        //   formdata.append(
        //     "service_custom_field_1",
        //     formValues.service_custom_field_1
        //   );
        // JSON.stringify
        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data.payment_mode === "online") {
                    cashfreePaymentGateway(res.data.pg_data)
                }

                return res
            })
            .catch((error) => {
                // actions.setErrors(error.response.data.errors);
                return error.response
            })

        dispatch({
            type: "PLACE_ORDER",
            payload: response,
        })
        dispatch(stopSubmitting())

        // setCookie("anonymous", response.data.token, 2);
    }
}

const format_delivery_time = (delivery_time) => {
    let hours = delivery_time.getHours()
    let minutes = delivery_time.getMinutes()
    let ampm = hours >= 12 ? "PM" : "AM"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes
    let strTime = hours + ":" + minutes + " " + ampm
    return strTime
}

const cashfreePaymentGateway = (response) => {
    const dropConfig = {
        components: [
            "order-details",
            "card",
            "netbanking",
            "app",
            "upi",
            "paylater",
            "credicardemi",
            "cardlessemi",
        ],
        orderToken: response.order_token,
        onSuccess: async (data) => {
            if (data.order && data.order.status == "PAID") {
                //order is paid
                //verify order status by making an API call to your server
                // using data.order.orderId
                // checkOrderStatus(data.order.orderId, response.orderID)
                const token = getCookie("userToken")
                const url_order = `/check-order-status/${data.order.orderId}/${response.orderID}`
                const check_order = await api
                    .get(url_order, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then((res) => {
                        window.location.href = `${baseurl}my-orders`
                    })
                    .catch((error) => {
                        alert("Oops! Payment Failed. Plese try again.")
                    })
            } else {
                alert("Oops! Payment Failed. Plese try again.")
            }
        },
        onFailure: function (data) {
            // console.log(data.order.errorText)
            alert("Oops! Payment Failed. Plese try again.")
        },
        style: {
            //to be replaced by the desired values
            backgroundColor: "#ffffff",
            color: "#11385b",
            fontFamily: "Lato",
            fontSize: "14px",
            errorColor: "#ff0000",
            theme: "light", //(or dark)
        },
    }

    const cashfree = new window.Cashfree()
    const paymentElement = document.getElementById("payment-form")
    try {
        cashfree.initialiseDropin(paymentElement, dropConfig)
    } catch (err) {
        console.log(err)
    }
}

const checkOrderStatus = (cfOrderId, orderID) => {
    return async (dispatch) => {
        const url = `/check-order-status/${cfOrderId}/${orderID}`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "PAYMENT_SUCCESS",
            payload: response,
        })
    }
}

//initiate paytm payment gateway
const initiatePaymentGateway = (response) => {
    var config = {
        root: "",
        flow: "DEFAULT",
        data: {
            orderId: response.orderID /* update order id */,
            token: response.body.txnToken /* update token value */,
            tokenType: "TXN_TOKEN",
            amount: response.amount /* update amount */,
        },
        handler: {
            notifyMerchant: function (eventName, data) {
                console.log("notifyMerchant handler function called")
                console.log("eventName => ", eventName)
                console.log("data => ", data)
            },
        },
    }

    // if(window.Paytm && window.Paytm.CheckoutJS){
    //     window.Paytm.CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
    //         // initialze configuration using init method
    //         window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
    //             // after successfully updating configuration, invoke JS Checkout
    //             window.Paytm.CheckoutJS.invoke();
    //         }).catch(function onError(error){
    //             console.log("error => ",error);
    //         });
    //     });
    // }

    if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.init(config)
            .then(function onSuccess() {
                window.Paytm.CheckoutJS.invoke()
            })
            .catch(function onError(error) {
                console.log("Error => ", error)
            })
    }
}

export const addToCart = (addedItem, grandTotal) => {
    return {
        type: "ADD_TO_CART",
        payload: {
            addedItem: addedItem,
            grandTotal: grandTotal,
        },
    }
}
export const removeToCart = (addedItem, grandTotal) => {
    return {
        type: "REMOVE_TO_CART",
        payload: addedItem,
        grandTotal: grandTotal,
    }
}
export const setOrderNote = (value) => {
    return {
        type: "SET_ORDER_NOTE",
        payload: value,
    }
}
export const setDine = (value) => {
    return {
        type: "SET_DINE",
        payload: value,
    }
}
export const setTakeaway = (value) => {
    return {
        type: "SET_TAKEAWAY",
        payload: value,
    }
}
export const setDelivery = (value) => {
    return {
        type: "SET_DELIVERY",
        payload: value,
    }
}

export const setPaymentMode = (value) => {
    return {
        type: "SET_PAYMENT_MODE",
        payload: value,
    }
}

export const logout = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/logout`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "LOGOUT_USER",
            payload: response,
        })
        delete_cookie("userToken")
    }
}

const setCookie = (cname, cvalue, exdays) => {
    var d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    var expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
}
const getCookie = (cname) => {
    var name = cname + "="
    var decodedCookie = decodeURIComponent(document.cookie)
    var ca = decodedCookie.split(";")
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === " ") {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ""
}
const delete_cookie = (name) => {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;"
}

//Show Password
export const showPassword = () => {
    return {
        type: "SHOW_PASSWORD",
    }
}

//Hide Password
export const hidePassword = () => {
    return {
        type: "HIDE_PASSWORD",
    }
}
const startSubmitting = () => {
    return {
        type: "START_SUBMITTING",
    }
}

const stopSubmitting = () => {
    return {
        type: "STOP_SUBMITTING",
    }
}

export const showModal = () => {
    return {
        type: "SHOW_MODAL",
    }
}

export const hideModal = () => {
    return {
        type: "HIDE_MODAL",
    }
}

export const myOrders = () => {
    return async (dispatch) => {
        const token = getCookie("userToken")
        const url = `/my-orders`
        const response = await api
            .get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "MY_ORDERS",
            payload: response,
        })
    }
}

export const employeeOrders = () => {
    return async (dispatch) => {
        const url = `/employee-orders`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "EMPLOYEE_ORDERS",
            payload: response,
        })
    }
}

export const employeePlaceOrder = (
    products,
    contact_id,
    grandTotal,
    service_custom_field_1,
    custom_field_1
    // shipping_address
    // formValues,
    // actions
) => {
    return async (dispatch) => {
        dispatch(startSubmitting())

        const token = getCookie("userToken")
        const url = `/place-employee-order`
        var formdata = new FormData()

        // formdata.append("service_custom_field_1", service_custom_field_1);
        service_custom_field_1 &&
            service_custom_field_1.length > 0 &&
            formdata.append("service_custom_field_1", service_custom_field_1)
        // formdata.append("service_custom_field_1", service_custom_field_1);
        formdata.append("contact_id", contact_id)
        formdata.append("res_waiter_id", 2)
        formdata.append("location_id", 2)
        formdata.append("custom_field_1", custom_field_1)
        formdata.append("discount_type", "percentage")
        formdata.append("discount_amount", 0)
        formdata.append("rp_redeemed", 0)
        formdata.append("rp_redeemed_amount", 0)
        formdata.append("tax_calculation_amount", 0)
        formdata.append("shipping_charges", 0)
        formdata.append("advance_balance", 0)
        formdata.append("payment[0][amount]", grandTotal)
        formdata.append("payment[0][method]", "cash")
        formdata.append("change_return", 0.0)
        formdata.append("is_suspend", 0)
        formdata.append("recur_interval_type", "days")

        formdata.append("size", "all")
        formdata.append("is_credit_sale", 0)
        formdata.append("final_total", grandTotal)
        formdata.append("discount_type_modal", "percentage")
        formdata.append("discount_amount_modal", 0.0)
        formdata.append("shipping_charges_modal", 0.0)
        formdata.append("status", "final")
        formdata.append("tax_rate_id", "")
        formdata.append("shipping_address", "")

        // formdata.append("resources",JSON.stringify(resources));

        let count = 1
        products.forEach((item) => {
            formdata.append(`products[${count}][product_type]`, "single")
            formdata.append(`products[${count}][unit_price]`, item.max_price)
            formdata.append(`products[${count}][line_discount_type]`, "fixed")
            formdata.append(`products[${count}][line_discount_amount]`, 0.0)
            formdata.append(`products[${count}][item_tax]`, 0.0)
            formdata.append(`products[${count}][product_id]`, item.id)
            formdata.append(`products[${count}][variation_id]`, item.id)
            formdata.append(
                `products[${count}][enable_stock]`,
                item.enable_stock
            )
            formdata.append(`products[${count}][quantity]`, item.quantity)
            formdata.append(`products[${count}][product_unit_id]`, item.unit_id)
            formdata.append(`products[${count}][base_unit_multiplier]`, 1)
            formdata.append(
                `products[${count}][unit_price_inc_tax]`,
                item.max_price
            )
            formdata.append(`products[${count}][tax_id]`, "")
            count++
        })

        // formValues.service_custom_field_1 &&
        //   formValues.service_custom_field_1.length > 0 &&
        //   formdata.append(
        //     "service_custom_field_1",
        //     formValues.service_custom_field_1
        //   );
        // JSON.stringify
        const response = await api
            .post(url, formdata, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res
            })
            .catch((error) => {
                // actions.setErrors(error.response.data.errors);
                return error.response
            })

        dispatch({
            type: "EMPLOYEE_PLACE_ORDER",
            payload: response,
        })
        dispatch(stopSubmitting())

        // setCookie("anonymous", response.data.token, 2);
    }
}

export const employeeLogin = (formValues, actions) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const url = `/employee-login`
        var formdata = new FormData()

        formdata.append("username", formValues.username)
        formdata.append("password", formValues.password)

        const response = await api
            .post(url, formdata)
            .then((res) => {
                return res
            })
            .catch((error) => {
                actions.setErrors(error.response.data.errors)
                return error.response
            })

        dispatch({
            type: "EMPLOYEE_LOGIN",
            payload: response,
        })
        dispatch(stopSubmitting())
        setCookie("employeeToken", response.data.access_token, 1)
    }
}

export const checkEmployee = () => {
    return async (dispatch) => {
        const token = getCookie("employeeToken")
        const url = `/check-employee`
        const response = await api
            .post(url, { token })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "CHECK_EMPLOYEE",
            payload: response,
        })
    }
}

export const lastOrderID = (payload) => {
    return async (dispatch) => {
        setCookie("lastOrderID", payload, 1)
    }
}

export const fetchProducts = () => {
    return async (dispatch) => {
        const url = `/employee/products`
        const response = await api
            .get(url)
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "EMPLOYEE_PRODUCTS",
            payload: response,
        })
    }
}

export const toggleProductStatus = (id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("employeeToken")
        const url = `/toggle-product-status/${id}`
        const response = await api
            .post(url, { token })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "EMPLOYEE_TOGGLE_PRODUCT_STATUS",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

export const employeeLogout = () => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("employeeToken")
        const url = `/employee-logout`
        const response = await api
            .post(url, { token })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "EMPLOYEE_LOGOUT",
            payload: response,
        })
        delete_cookie("employeeToken")
        dispatch(stopSubmitting())
    }
}

export const storeSearchTerm = (term, type) => {
    return {
        type: type,
        payload: term,
    }
}

export const storeMenu = (menu, type) => {
    return {
        type: type,
        payload: menu,
    }
}

export const storeOrders = (orders) => {
    return {
        type: "STORE_ORDERS",
        payload: orders,
    }
}

export const storeProducts = (products) => {
    return {
        type: "STORE_PRODUCTS",
        payload: products,
    }
}

export const setDeliveryTime = (time) => {
    return {
        type: "SET_DELIVERY_TIME",
        payload: time,
    }
}

export const printStatus = (id) => {
    return async (dispatch) => {
        dispatch(startSubmitting())
        const token = getCookie("employeeToken")
        const url = `/print-status/${id}`
        const response = await api
            .post(url, { token })
            .then((res) => {
                return res
            })
            .catch((error) => {
                return error.response
            })

        dispatch({
            type: "EMPLOYEE_ORDER_PRINT_STATUS",
            payload: response,
        })
        dispatch(stopSubmitting())
    }
}

const clearCart = () => {
    return {
        type: "CLEAR_CART",
    }
}

export const retakeOrders = (data) => {
    return async (dispatch) => {
        dispatch(clearCart())
        dispatch({
            type: "RETAKE_NEW_ORDER",
            payload: data,
        })
    }
}
