import React from "react";

import "./loading-btn.css";
import Spinner from "./spinner.gif";

const LoadingBtn = ({ color }) => {
  return (
    <div className={`text-${color}   m-auto`}>
      <div className="flex text-center">
        <span className="inline-icon spinner">
          <img src={Spinner} alt="loading" />
        </span>
        <span className="text-center">Please Wait</span>
      </div>
    </div>
  );
};

export default LoadingBtn;
