import React, { Component } from "react";
import { Dialog } from "@react-md/dialog";
import { Link } from "react-router-dom";
import { useToggle } from "@react-md/utils";
import LoginDetails from "./LoginDetails";
import { list } from "purgecss/node_modules/postcss";

const LoginCapDetailsDesktop = () => {
  const [visible, enable, disable] = useToggle(false);

  return (
    <>
      <div
        onClick={enable}
        class="  focus:outline-none      desktopview-btn  cursor-pointer  "
      >
        Login
      </div>
      {/* <li
        onClick={enable}
        class="self-center font-bold text-xs text-right home-nav-item text-account"
      >
        Login
      </li> */}

      <Dialog
        id="simple-dialog"
        visible={visible}
        aria-labelledby="dialog-title"
        className="pb-10 popup-login"
        onRequestClose={disable}
      >
        <span className="close3" id="dialog-close" onClick={disable}>
          &times;
        </span>
        <LoginDetails />
      </Dialog>
    </>
  );
};

export default LoginCapDetailsDesktop;
