// export const baseurl = "/thebackyardcafe";

export const baseurl = "/"
export const baseurl2 = "https://thebackyardcafe.in/admin/"
//export const baseurl2 = "http://127.0.0.1:8000";
export const openTab = (tabName, btnName) => {
    var i, tabcontent, tablinks
    tabcontent = document.getElementsByClassName("tabcontent")
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none"
    }
    tablinks = document.getElementsByClassName("tablinks")
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" active", "")
    }
    document.querySelector(`#${tabName}`).style.display = "block"
    document.querySelector(`#${btnName}`).className += " active"
}

export const scrollToTop = () => {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}
