import React from "react";

import { Link } from "react-router-dom";
import { baseurl } from "../../../Custom";
import { connect } from "react-redux";

import { Form, Formik, Field } from "formik";
import ErrorMsg from "../../../includes/inputs/ErrorMsg";
import { userLogin, validateOtp } from "../../../../actions";
// import ValidateOTP from "./ValidateOTP";
// import ChangePassword from "./ChangePassword";
import LoadingBtn from "../../../includes/btn/LoadingBtn";

import DesktopRegister from "./DesktopRegister";

import MobileRegister from "./MobileRegister";
class LoginDetails extends React.Component {
  myTextInput = ({ name, field, placeholder, type }) => {
    return (
      <div className="w-full">
        <div className="text-input">
          <input
            placeholder={placeholder}
            type={type}
            name={name}
            className={`appearance-none   w-full py-2 px-3 text-sm focus:outline-none    `}
            {...field}
          />
        </div>
      </div>
    );
  };

  submitButton = (values, actions) => {
    this.props.userLogin(values, actions);
  };
  validateOtp = (values, actions) => {
    this.props.validateOtp(values, actions);
  };
  validate = (values) => {
    let errors = {};

    if (!values.mobile) {
      errors.mobile = <div className="error-msg">Enter Your Mobile Number</div>;
    } else if (!/^[0-9]{10}$/i.test(values.mobile)) {
      errors.mobile = <div className="error-msg">Invalid Mobile Number </div>;
    }

    if(!values.password){
      errors.password = 'Enter Your Password'
    }

    return errors;
  };
  render() {
    const { success, verifyOtp, mobile } = this.props.registerform;

    const { isSubmitting } = this.props.commonData;
    // verifyOtp ? (
    //     <ChangePassword />
    //   ) : forgotPassword ? (
    //     <ValidateOTP />
    //   ) :
    // if (verifyOtp) {
    //   window.location.href = `${baseurl}`;
    // }

    return (
      //     verifyOtp ? (
      //   (window.location.href = `${baseurl}`)
      // ) : success ? (
      <div className="login-container">
        {/*forgot password heading  */}
        <div className="login-heading">Login</div>
        {verifyOtp ? (
          (window.location.href = `${baseurl}`)
        ) : success ? (
          <Formik
            initialValues={{
              mobile: mobile ? mobile : "",
              otp: "",
              //   address_type: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.otp) {
                errors.otp = <div className="error-msg">Enter OTP</div>;
              }

              return errors;
            }}
            onSubmit={(values, actions) => {
              this.validateOtp(values, actions);
            }}
            enableReinitialize={true}
          >
            {(props: FormikProps<any>) => (
              <Form>
                <input className="invisible-input" />
                <div className=" pb-4 text-xs text-center">
                  Enter the otp send to{" "}
                  <span className="font-bold "> {mobile} </span>
                </div>
                <div className="   form-input border  ">
                  <Field
                    className=" border"
                    component={this.myTextInput}
                    name="otp"
                    placeholder="Enter OTP"
                    type="number"
                    autofocus
                  ></Field>
                </div>
                {/* <label className="address-field" for="store_name">Business Name</label> */}
                {props.touched.otp && props.errors.otp && (
                  <ErrorMsg msg={props.errors.otp} />
                )}
                <button
                  type="submit"
                  className="continue-login-btn mb-5  text-center focus:outline-none"
                >
                  {isSubmitting ? <LoadingBtn /> : `VERIFY`}
                </button>
              </Form>
            )}
          </Formik>
        ) : (
          //   </div>
          //   <div className="login-container">
          //     {/*forgot password heading  */}
          //     <div className="login-heading ">
          //       Login or Register <br /> Using OTP
          //     </div>

          <Formik
            initialValues={{
              mobile: "",
              password: ""
            }}
            validate={this.validate}
            onSubmit={(values, actions) => {
              this.submitButton(values, actions);
            }}
            enableReinitialize={true}
          >
            {(props: FormikProps<any>) => (
              <Form>
                <input className="invisible-input" />
                <div className="   form-input border  ">
                  <Field
                    component={this.myTextInput}
                    name="mobile"
                    placeholder="Enter Your Mobile Number  "
                    type="text"
                    className="text-xl"
                  ></Field>
                </div>
                {/* <label className="address-field" for="store_name">Business Name</label> */}
                {props.touched.mobile && props.errors.mobile && (
                  <ErrorMsg msg={props.errors.mobile} />
                )}
                <div className="   form-input border  ">
                  <Field
                    component={this.myTextInput}
                    name="password"
                    placeholder="Enter Your Password  "
                    type="password"
                    className="text-xl"
                  ></Field>
                </div>
                {/* <label className="address-field" for="store_name">Business Name</label> */}
                {props.touched.password && props.errors.password && (
                  <ErrorMsg msg={props.errors.password} />
                )}
                <button
                  type="submit"
                  className="continue-login-btn text-center focus:outline-none"
                >
                  {isSubmitting ? <LoadingBtn /> : `LOGIN`}
                </button>
                {/* <div className=" text-center  looking-to-create-acc-div">
                  <span>
                    New User?
                    <MobileRegister />
                    <DesktopRegister />
                  </span>
                </div> */}
              </Form>
            )}
          </Formik>
        )}
        <div className="  text-center m-auto looking-to-create-acc-div">
          <div className="flex m-auto text-center gap-2 ">
            <p className="   "> New User ? </p>

            <MobileRegister />

            <DesktopRegister />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registerform: state.registerData,
    commonData: state.commonFunctions,
  };
};

export default connect(mapStateToProps, { userLogin, validateOtp })(
  LoginDetails
);
