import React from "react"

import { Link } from "react-router-dom"
import { connect } from "react-redux"

// import LoginAgainMobileView from "./mobileview/LoginAgainMobileView";
import { Form, Formik, Field } from "formik"
import ErrorMsg from "../../../includes/inputs/ErrorMsg"
import { fetchRegister, validateOtp2 } from "../../../../actions"
// import ValidateOTP from "./ValidateOTP";
// import ChangePassword from "./ChangePassword";
import LoadingBtn from "../../../includes/btn/LoadingBtn"
import LoginCapDetailsMobile from "./LoginCapDetailsMobile"
import LoginCapDetailsDesktop from "./LoginCapDetailsDesktop"
import RegisterComplete from "./RegisterComplete"

class RegisterDetails extends React.Component {
    myTextInput = ({ name, field, placeholder, type }) => {
        return (
            <div className="w-full">
                <div className="text-input">
                    <input
                        placeholder={placeholder}
                        type={type}
                        name={name}
                        className={`appearance-none   w-full py-2 px-3 text-sm focus:outline-none    `}
                        {...field}
                    />
                </div>
            </div>
        )
    }

    submitButton = (values, actions) => {
        this.props.fetchRegister(values, actions)
    }
    validateOtp2 = (values, actions) => {
        this.props.validateOtp2(values, actions)
    }

    render() {
        const { success, verifyOtp2, mobile } = this.props.registerform

        const { isSubmitting } = this.props.commonData
        // verifyOtp ? (
        //     <ChangePassword />
        //   ) : forgotPassword ? (
        //     <ValidateOTP />
        //   ) :
        // if (verifyOtp) {
        //   window.location.href = `${baseurl}`;
        // }

        return (
            <div className="login-container">
                {/*forgot password heading  */}
                <div className="login-heading">Register</div>
                {verifyOtp2 ? (
                    <RegisterComplete />
                ) : success ? (
                    <Formik
                        initialValues={{
                            mobile: mobile ? mobile : "",
                            otp: "",
                            //   address_type: "",
                        }}
                        validate={(values) => {
                            const errors = {}

                            if (!values.otp) {
                                errors.otp = (
                                    <div className="error-msg">Enter OTP</div>
                                )
                            }

                            return errors
                        }}
                        onSubmit={(values, actions) => {
                            this.validateOtp2(values, actions)
                        }}
                        enableReinitialize={true}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <input className="invisible-input" />
                                {mobile}
                                <div className="   form-input border  ">
                                    <Field
                                        className=" border"
                                        component={this.myTextInput}
                                        name="otp"
                                        placeholder="Enter OTP"
                                        type="number"
                                    ></Field>
                                </div>
                                {/* <label className="address-field" for="store_name">Business Name</label> */}
                                {props.touched.otp && props.errors.otp && (
                                    <ErrorMsg msg={props.errors.otp} />
                                )}
                                <button
                                    type="submit"
                                    className="continue-login-btn mb-5  text-center focus:outline-none"
                                >
                                    {isSubmitting ? <LoadingBtn /> : `VERIFY`}
                                </button>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    // </div>
                    // <div className="login-container">

                    //   <div className="login-heading">
                    //     Login or Register <br /> Using OTP
                    //   </div>

                    <Formik
                        initialValues={{
                            mobile: "",
                            email: "",
                            full_name: "",
                            password: "",
                            password_confirmation: "",
                        }}
                        validate={(values) => {
                            const errors = {}
                            if (!values.mobile) {
                                errors.mobile = "Enter Your Mobile Number"
                            } else if (!/^[0-9]{10}$/i.test(values.mobile)) {
                                errors.mobile = "Invalid Mobile Number"
                            }
                            if (!values.email) {
                                errors.email = "Enter Your Email Address"
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                    values.email
                                )
                            ) {
                                errors.email = "Invalid email address"
                            }
                            if (!values.full_name) {
                                errors.full_name = "Enter Name"
                            }
                            if (!values.password) {
                                errors.password = "Enter Password"
                            }
                            if (!values.password_confirmation) {
                                errors.password_confirmation =
                                    "Confirm Password"
                            }
                            return errors
                        }}
                        onSubmit={(values, actions) => {
                            this.submitButton(values, actions)
                        }}
                        enableReinitialize={true}
                    >
                        {(props: FormikProps<any>) => (
                            <Form>
                                <input className="invisible-input" />
                                <div className="   form-input border  ">
                                    <Field
                                        component={this.myTextInput}
                                        name="full_name"
                                        placeholder="Enter Full Name  "
                                        type="text"
                                    ></Field>
                                </div>

                                {props.touched.full_name &&
                                    props.errors.full_name && (
                                        <ErrorMsg
                                            msg={props.errors.full_name}
                                        />
                                    )}

                                <div className="   form-input border mt-2">
                                    <Field
                                        component={this.myTextInput}
                                        name="email"
                                        placeholder="Enter Email "
                                        type="email"
                                    ></Field>
                                </div>

                                {props.touched.email && props.errors.email && (
                                    <ErrorMsg msg={props.errors.email} />
                                )}

                                <div className="   form-input border mt-2 ">
                                    <Field
                                        component={this.myTextInput}
                                        name="mobile"
                                        placeholder="Enter Mobile Number  "
                                        type="text"
                                    ></Field>
                                </div>

                                {props.touched.mobile &&
                                    props.errors.mobile && (
                                        <ErrorMsg msg={props.errors.mobile} />
                                    )}
                                <div className="   form-input border mt-2 ">
                                    <Field
                                        component={this.myTextInput}
                                        name="password"
                                        placeholder="Enter Password  "
                                        type="password"
                                    ></Field>
                                </div>

                                {props.touched.password &&
                                    props.errors.password && (
                                        <ErrorMsg msg={props.errors.password} />
                                    )}
                                <div className="   form-input border mt-2 ">
                                    <Field
                                        component={this.myTextInput}
                                        name="password_confirmation"
                                        placeholder="Confirm Password  "
                                        type="password"
                                    ></Field>
                                </div>
                                {props.touched.password_confirmation &&
                                    props.errors.password_confirmation && (
                                        <ErrorMsg
                                            msg={
                                                props.errors
                                                    .password_confirmation
                                            }
                                        />
                                    )}
                                <button
                                    type="submit"
                                    className="continue-login-btn text-center focus:outline-none"
                                >
                                    {isSubmitting ? <LoadingBtn /> : `REGISTER`}
                                </button>
                            </Form>
                        )}
                    </Formik>
                )}
                <div className="  text-center m-auto looking-to-create-acc-div">
                    <div className="flex m-auto text-center gap-2 ">
                        <p className="   "> Already have an account? </p>

                        <LoginCapDetailsDesktop />
                        <LoginCapDetailsMobile />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        registerform: state.registerData,
        commonData: state.commonFunctions,
    }
}

export default connect(mapStateToProps, { fetchRegister, validateOtp2 })(
    RegisterDetails
)
