import { combineReducers } from "redux"

const commonFunctionsReducer = (
    state = {
        isLoading: false,
        isSubmitting: false,
        modalOpen: false,
    },
    action
) => {
    switch (action.type) {
        case "START_LOADING": {
            let newState = { ...state, isLoading: true }
            return newState
        }
        case "STOP_LOADING": {
            let newState = { ...state, isLoading: false }
            return newState
        }
        case "START_SUBMITTING": {
            let newState = { ...state, isSubmitting: true }
            return newState
        }
        case "STOP_SUBMITTING": {
            let newState = { ...state, isSubmitting: false }
            return newState
        }
        case "SHOW_MODAL": {
            let newState = { ...state, modalOpen: true }
            return newState
        }
        case "HIDE_MODAL": {
            let newState = { ...state, modalOpen: false }
            return newState
        }
        default:
            return state
    }
}

const registerDataReducer = (
    state = {
        mobile: null,
        verifyOtp: false,
        otp: false,
        login: false,
        loginLoading: true,
        isLoggedIn: false,
        user: {},
        orders: [],
        sell_lines: [],
        verifyOtp2: false,
    },
    action
) => {
    switch (action.type) {
        case "LOGOUT_USER": {
            if (action.payload.status === 200) {
                let newState = { ...state, login: false, loginLoading: false }
                return newState
            } else {
                let newState = { ...state, login: false, loginLoading: false }
                return newState
            }
        }
        case "FETCH_REGISTER": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    otp: true,
                    mobile: action.payload.data.mobile,
                    success: true,
                    login: true,
                    loginLoading: false,
                    isLoggedIn: true,
                    verifyOtp2: true,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    otp: false,
                    verifyOtp2: false,
                    mobile: null,
                    success: false,
                    login: false,
                    loginLoading: false,
                    isLoggedIn: false,
                }
                return newState
            }
        }
        case "USER_LOGIN": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    otp: true,
                    mobile: action.payload.data.mobile,
                    verifyOtp: true,
                    login: true,
                    loginLoading: true,
                    isLoggedIn: true,
                    success: true,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    otp: false,
                    mobile: null,
                    success: false,
                    verifyOtp: false,
                    login: false,
                    loginLoading: false,
                    isLoggedIn: false,
                }
                return newState
            }
        }
        case "VALIDATE_OTP": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    verifyOtp: true,
                    login: true,
                    loginLoading: true,
                    isLoggedIn: true,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    verifyOtp: false,
                    login: false,
                    loginLoading: false,

                    isLoggedIn: false,
                }
                return newState
            }
        }
        case "VALIDATE_OTP2": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    verifyOtp2: true,
                    login: true,
                    loginLoading: true,
                    isLoggedIn: true,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    verifyOtp2: false,
                    login: false,
                    loginLoading: false,

                    isLoggedIn: false,
                }
                return newState
            }
        }
        case "CHECK_USER": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    login: true,
                    loginLoading: false,
                    user: action.payload.data,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    login: false,
                    loginLoading: false,
                    user: {},
                }
                return newState
            }
        }
        case "MY_ORDERS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    orders: action.payload.data,
                    // sell_lines: action.payload.data,
                }
                return newState
            } else {
                //let newState = { ...state, cart: null };
                return state
            }
        }
        default:
            return state
    }
}
const homepageReducer = (
    state = {
        menu: [],
        config: {},
        status: false,
        gallery: [],
        products: [],
        success: false,
        service_custom_field_1: null,
        shipping_address: "",
        custom_field_1: "Dine",
        payment_mode: "online",
        delivery_time: null,
        minTime: null,
        full_menu: [],
    },
    action
) => {
    switch (action.type) {
        case "SET_TAKEAWAY": {
            return {
                ...state,
                custom_field_1: action.payload,
            }
        }
        case "SET_DELIVERY_TIME": {
            return {
                ...state,
                delivery_time: action.payload,
            }
        }
        case "SET_DINE": {
            return {
                ...state,
                custom_field_1: action.payload,
            }
        }
        case "SET_ORDER_NOTE": {
            return {
                ...state,
                service_custom_field_1: action.payload,
            }
        }

        case "SET_PAYMENT_MODE": {
            return {
                ...state,
                payment_mode: action.payload,
            }
        }

        case "STORE_FULL_MENU": {
            return {
                ...state,
                full_menu: action.payload,
            }
        }

        case "RESTORE_MENU": {
            return {
                ...state,
                menu: action.payload,
            }
        }

        case "FETCH_CATEGORIES": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    menu: action.payload.data,
                    full_menu: action.payload.data,
                }
                return newState
            } else {
                let newState = { ...state, menu: [], full_menu: [] }
                return newState
            }
        }

        case "FETCH_GALLERY": {
            if (action.payload.status === 200) {
                let newState = { ...state, gallery: action.payload.data }
                return newState
            } else {
                let newState = { ...state, gallery: [] }
                return newState
            }
        }

        case "FETCH_PICKEDUP_LOCATION": {
            if (action.payload.status === 200) {
                let newState = { ...state, config: action.payload.data }
                return newState
            } else {
                let newState = { ...state, config: {} }
                return newState
            }
        }
        case "PLACE_ORDER": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    products: action.payload.products,
                    service_custom_field_1:
                        action.payload.service_custom_field_1,
                    status: true,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    products: [],
                    service_custom_field_1: "",
                    status: false,
                }
                return newState
            }
        }

        case "PAYMENT_SUCCESS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    success: true,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    success: false,
                }
                return newState
            }
        }

        case "SET_DELIVERY": {
            return {
                ...state,
                shipping_address: action.payload,
            }
        }

        default:
            return state
    }
}
const cartReducer = (
    state = {
        addedItems: [],
        total: 0,
        totalquantity: 0,
    },
    action
) => {
    //INSIDE HOME COMPONENT
    switch (action.type) {
        case "ADD_TO_CART": {
            return {
                ...state,
                addedItems: action.payload.addedItem,
                total: action.payload.grandTotal,
                totalquantity: action.payload.totalquantity,
            }
        }
        case "REMOVE_TO_CART": {
            return {
                ...state,
                total: 0,
                addedItems: state.addedItems.filter(
                    (item) => item.id !== action.payload
                ),
            }
        }

        case "CLEAR_CART": {
            return {
                ...state,
                addedItems: [],
                total: 0,
                totalquantity: 0,
            }
        }

        default:
            return state
    }
}

const enquiryformReducer = (
    state = {
        form: false,
        selected_date: "",
        updateprofile: false,
    },
    action
) => {
    switch (action.type) {
        case "NEW_ENQUIRY": {
            if (action.payload.status === 200) {
                let newState = { ...state, form: true }
                return newState
            } else {
                let newState = { ...state, form: false }
                return newState
            }
        }

        case "SAVE_DATE": {
            let newState = { ...state, selected_date: action.payload }
            return newState
        }
        default:
            return state
    }
}

const profileformReducer = (
    state = {
        updateprofile: false,
    },
    action
) => {
    switch (action.type) {
        case "UPDATE_PROFILE": {
            if (action.payload.status === 200) {
                let newState = { ...state, updateprofile: true }
                return newState
            } else {
                let newState = { ...state, updateprofile: false }
                return newState
            }
        }

        default:
            return state
    }
}

const employeeReducer = (
    state = {
        orders: [],
        full_orders: [],
        ordersPlaced: false,
        login: false,
        loginLoading: true,
        isLoggedIn: false,
        user: {},
        products: [],
        full_products: [],
        ordersSearch: "",
        placeOrderSearch: "",
        productsSearch: "",
    },
    action
) => {
    switch (action.type) {
        case "EMPLOYEE_ORDERS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    orders: action.payload.data,
                    full_orders: action.payload.data,
                }
                return newState
            } else {
                return state
            }
        }
        case "EMPLOYEE_LOGIN": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    isLoggedIn: true,
                    loginLoading: false,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    isLoggedIn: false,
                    loginLoading: true,
                }
                return newState
            }
        }
        case "EMPLOYEE_PLACE_ORDER": {
            if (action.payload.status === 200) {
                let newState = { ...state, ordersPlaced: true }
                return newState
            } else {
                let newState = { ...state, ordersPlaced: false }
                return newState
            }
        }
        case "CHECK_EMPLOYEE": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    user: action.payload.data,
                    login: true,
                    loginLoading: false,
                }
                return newState
            } else if (action.payload.status === 401) {
                let newState = {
                    ...state,
                    user: {},
                    login: false,
                    loginLoading: false,
                }
                return newState
            } else {
                let newState = {
                    ...state,
                    user: {},
                    login: false,
                    loginLoading: true,
                }
                return newState
            }
        }
        case "EMPLOYEE_PRODUCTS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    products: action.payload.data,
                    full_products: action.payload.data,
                }
                return newState
            } else {
                let newState = { ...state, products: [], full_products: [] }
                return newState
            }
        }
        case "EMPLOYEE_TOGGLE_PRODUCT_STATUS": {
            if (action.payload.status === 200) {
                let newState = {
                    ...state,
                    products: state.products.map((product) =>
                        product.id === action.payload.data.id
                            ? {
                                  ...product,
                                  is_inactive: action.payload.data.is_inactive,
                              }
                            : product
                    ),
                }
                return newState
            } else {
                return state
            }
        }
        case "EMPLOYEE_LOGOUT": {
            let newState = { ...state, user: {}, login: false }
            return newState
        }
        case "EMPLOYEE_ORDERS_SEARCH": {
            let newState = { ...state, ordersSearch: action.payload }
            return newState
        }
        case "EMPLOYEE_PLACE_ORDER_SEARCH": {
            let newState = { ...state, placeOrderSearch: action.payload }
            return newState
        }
        case "EMPLOYEE_PRODUCTS_SEARCH": {
            let newState = { ...state, productsSearch: action.payload }
            return newState
        }

        case "STORE_ORDERS": {
            let newState = { ...state, orders: action.payload }
            return newState
        }

        case "STORE_PRODUCTS": {
            let newState = { ...state, products: action.payload }
            return newState
        }

        case "RETAKE_NEW_ORDER": {
            let newState = {
                ...state,
                //products: action.payload,
                ordersPlaced: false,
                placeOrderSearch: "",
            }
            return newState
        }

        default:
            return state
    }
}

/* Manage Modal State */
const modalReducer = (
    state = { modalOpen: false, modalName: null },
    action
) => {
    switch (action.type) {
        case "OPEN_MODAL": {
            let newState = {
                ...state,
                modalOpen: true,
                modalName: action.payload,
            }
            return newState
        }
        case "CLOSE_MODAL": {
            let newState = { ...state, modalOpen: false, modalName: null }
            return newState
        }
        default:
            return state
    }
}

export default combineReducers({
    modal: modalReducer,
    registerData: registerDataReducer,
    homepageData: homepageReducer,
    commonFunctions: commonFunctionsReducer,
    cartData: cartReducer,
    enquiryformData: enquiryformReducer,
    profileformData: profileformReducer,
    employeeData: employeeReducer,
})
