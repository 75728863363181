import React, { Component } from "react";

import { Link, withRouter } from "react-router-dom";

import { baseurl, openTab } from "../../Custom";

// import ImageAbout from "./ImageAbout";
class NavbarCart extends Component {
  render() {
    const { item, price, quantity, image } = this.props;
    return (
      <div class=" grid grid-cols-3 md:grid-cols-4 gap-2 navbar-cart-border mb-6">
        <div className="text-sm   img-div-remove ">
          <img
            // src={`${baseurl}restabook/10.jpg`}
            src={`${baseurl}${image}`}
            alt="logo"
            className="   w-full"
          />
        </div>

        <div className="text-xs    text-gray-800 font-bold col-span-2 self-center">
          {item}
          <h1 className="text-gray-600 ">
            {quantity} x {price}
          </h1>
        </div>

        <div className="    text-gray-800 self-center text-right mail">x</div>
      </div>
    );
  }
}

export default NavbarCart;
