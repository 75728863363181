import React, { Component } from 'react'
import { baseurl } from "../../../Custom";

export default class RegisterComplete extends Component {

    componentDidMount(){
        // setTimeout(() => {
        //     (window.location.href = `${baseurl}`)
        // }, 5000)
    }

  render() {
    return (
        <div className="login-container">
            <div className='flex justify-center'>
                <div>
                    <div className='flex justify-center'>
                        <img src={`${baseurl}restabook/checked.png`} className="w-1/3" alt="complete" />
                    </div>
                    <h1 className='text-center font-bold text-2xl'>Registration complete. Please wait...</h1>
                </div>
            </div>
        </div>
    )
  }
}
