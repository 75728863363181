import React, { Component } from "react";
import { Dialog } from "@react-md/dialog";
import { Link } from "react-router-dom";
import { useToggle } from "@react-md/utils";
import LoginDetails from "./LoginDetails";
import { list } from "purgecss/node_modules/postcss";
import RegisterDetails from "./RegisterDetails";

const DesktopLogin = () => {
  const [visible, enable, disable] = useToggle(false);

  return (
    <>
      <div
        onClick={enable}
        class="ripple focus:outline-none    desktopview-btn     "
      >
        Register
      </div>

      <Dialog
        id="simple-dialog"
        visible={visible}
        aria-labelledby="dialog-title"
        className="pb-10 popup-login"
        onRequestClose={disable}
      >
        <span className="close3" id="dialog-close" onClick={disable}>
          &times;
        </span>
        <RegisterDetails />
      </Dialog>
    </>
  );
};

export default DesktopLogin;
