import axios from "axios"

export const api = axios.create({
    baseURL:
        //"http://localhost:8000/api",

        "https://thebackyardcafe.in/admin/api/",

    headers: { "Content-Type": "application/json" },
})
