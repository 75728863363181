import React, { Component } from "react";

import { baseurl } from "../../Custom";

import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { checkUser } from "../../../actions";
import MobileLogin from "./Register/MobileLogin";
class SideMenuDetails extends Component {
  componentDidMount() {
    this.props.checkUser();
  }
  render() {
    const { login, loginLoading, user, isLoggedIn } = this.props.user;
    function handleScroll() {
      window.scroll({
        top: 200,
        left: 100,
        behavior: "smooth",
      });
    }
    return (
      <>
        <div className="sidemenu-top">
          <img
            src={`${baseurl}restabook/20211018_095743_0000-removebg-preview.png`}
            // src="restabook/logo.png"
            alt="logo"
            className="h-32 m-auto "
          />
        </div>
        <div className="grid grid-cols-1 font-semibold text-sm pl-2 pt-4">
          <Link to="/" className="focus:outline-none pb-2">
            HOME{" "}
          </Link>
          <Link to="/menu" className="focus:outline-none pb-2">
            MENU
          </Link>
          {/* <a onClick={() => handleScroll()} className="focus:outline-none pb-2">
            ABOUT{" "}
          </a> */}
          <Link
            to="/"
            onClick={() => handleScroll()}
            className="focus:outline-none pb-2"
          >
            ABOUT
          </Link>
          <Link to="/enquiry" className="focus:outline-none pb-2">
            ENQUIRY
          </Link>
          <div className="focus:outline-none pb-2">
            {!loginLoading && !login ? (
              <MobileLogin />
            ) : !loginLoading && login ? (
              <>
                {" "}
                <Link to="/logout" className="focus:outline-none pb-2">
                  <div className="focus:outline-none pb-2">LOGOUT</div>
                </Link>
                <Link to="/my-orders" className="focus:outline-none pb-2">
                  MY ORDERS
                </Link>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
// export default SideMenuDetails;
const mapStateToProps = (state) => {
  return {
    user: state.registerData,
  };
};

export default withRouter(
  connect(mapStateToProps, { checkUser })(SideMenuDetails)
);
